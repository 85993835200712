import React from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-blue.png"

// Images
import BlueLabel from "../images/logo-blue-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"
import BlueLabelEU from "../images/europe/logo.svg"
import GoldLabelEU from "../images/europe/download-logo-gold.svg"
import SilverLabelEU from "../images/europe/download-logo-silver.svg"

const Labeloverview = () => {
  return (
    <React.Fragment>
      <PageTitle title={"Vergleich"} />
      <Layout>
        <div className="vergleich-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/en")}>Home</a>
              </li>
              <li>
                <a>Label Overview</a>
              </li>
            </ul>
          </div>

          <div className="vergleich">
            <div className="vergleich-top">
              <h1>Overview Cyber Trust Labels</h1>
              <div className="vergleich-horizontal-line"></div>
              <div className="vergleich-text">
                There are three distinct quality levels of the label in order to
                be able to cover different target groups. The quality levels
                differ in terms of their security claim and the degree of
                assurance of the review (assurance level).
              </div>
            </div>

            {/* <div class="vergleich__block--ghost--container">
              <div class="vergleich__block--ghost--slider"></div>
            </div> */}
            <div className="vergleich__block">
              <div className="vergleich__table">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <div className="image">
                          <img
                            className="mw-100"
                            src={
                              process.env.GATSBY_VARIANT === "Austria"
                                ? BlueLabel
                                : BlueLabelEU
                            }
                            style={{ height: "59px" }}
                            alt="Cyber Trust Austria Standard Label"
                            title="Cyber Trust Austria Standard Label"
                          />
                        </div>
                      </th>
                      <th>
                        <div className="image">
                          <img
                            className="mw-100"
                            src={
                              process.env.GATSBY_VARIANT === "Austria"
                                ? SilverLabel
                                : SilverLabelEU
                            }
                            style={{ height: "59px" }}
                            alt="Cyber Trust Austria Silber Label"
                            title="Cyber Trust Austria Silber Label"
                          />
                        </div>
                      </th>
                      <th>
                        <div className="image">
                          <img
                            className="mw-100"
                            src={
                              process.env.GATSBY_VARIANT === "Austria"
                                ? GoldLabel
                                : GoldLabelEU
                            }
                            style={{ height: "59px" }}
                            alt="Cyber Trust Austria Gold Label"
                            title="Cyber Trust Austria Gold Label"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>
                      <td>Label</td>
                      <td>Label Silber</td>
                      <td>Label Gold</td>
                    </tr>
                    <tr>
                      <td>Target Groups</td>
                      <td>
                        SMEs who take cybersecurity seriously and want to show
                        that to its customers Suppliers of Operators of
                        essential Services according to §16 NIS-Law (BGBL Nr.
                        111/2018) in less critical areas
                      </td>
                      <td>
                        Large companies and corporates Suppliers of Operators of
                        essential Services according to §16 NIS-Law (BGBL Nr.
                        111/2018) in more critical areas (e.g. software
                        companies, processors of sensitive data, etc.)
                      </td>
                      <td>
                        Large companies and corporates Suppliers of Operators of
                        essential Services according to §16 NIS-Law (BGBL Nr.
                        111/2018) in more critical areas (e.g. software
                        companies, processors of sensitive data, etc.)
                      </td>
                    </tr>
                    <tr>
                      <td>Baseline</td>
                      <td>KSV1870 Cyber Risk B Rating</td>
                      <td>KSV1870 Cyber Risk A Rating</td>
                      <td>KSV1870 Cyber Risk A+ Rating</td>
                    </tr>
                    <tr>
                      <td>Assurance Level</td>
                      <td>Validated Self-declaration</td>
                      <td>Validated Self-declaration</td>
                      <td>Third Party Audit</td>
                    </tr>
                    <tr>
                      <td>Assurance Method</td>
                      <td>
                        Answering a questionnaire followed by external
                        validation; acceptance of eventual random surveillance
                        audits, conduction of an automated web risk scoring
                      </td>
                      <td>
                        Answering a questionnaire followed by external
                        validation; acceptance of eventual random surveillance
                        audits, conduction of an automated web risk scoring
                      </td>
                      <td>
                        Evidence based third party audit by a qualified auditor
                        (QuaSte accreditation by NIS authority); conduction of
                        an automated web risk scoring
                      </td>
                    </tr>
                    <tr>
                      <td>Security Claim</td>
                      <td>Baseline Security</td>
                      <td>Advanced Security</td>
                      <td>Advanced Security</td>
                    </tr>
                    <tr>
                      <td>Criteria</td>
                      <td>14</td>
                      <td>25</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>Requirements</td>
                      <td>Questionnaire B-Rating</td>
                      <td>Questionnaire A-Rating</td>
                      <td>Questionnaire A-Rating</td>
                    </tr>
                    <tr>
                      <td>Requirements for the Label</td>
                      <td>Valid KSV1870 CyberRisk B-Rating of 190 or better</td>
                      <td>Valid KSV1870 CyberRisk A-Rating of 190 or better</td>
                      <td>
                        Valid KSV1870 CyberRisk A+ Ratings of 190 or better
                      </td>
                    </tr>

                    <tr>
                      <td>Validity</td>
                      <td>1 Year</td>
                      <td>1 Year</td>
                      <td>1 Year</td>
                    </tr>

                    <tr>
                      <td>Label fee (initial)</td>
                      <td>890€ </td>
                      <td>1390€</td>
                      <td>1490€</td>
                    </tr>
                    <tr>
                      <td>Label fee (consecutive years)</td>
                      <td>790€</td>
                      <td>1190€</td>
                      <td>1290€</td>
                    </tr>
                    <tr>
                      <td>Audit fee</td>
                      <td>-</td>
                      <td>-</td>
                      <td>According to audit partner</td>
                    </tr>

                    <tr>
                      <td>Renewal Process</td>
                      <td>
                        Renewed answering of the questionnaire*
                        <div className="vergleich__table--button">
                          <Link
                            to="/en/application-procedure-standard"
                            className="page__btn page__btn--blue-border"
                            style={{
                              marginTop: 50,
                              padding: "14px 14px",
                              minWidth: 210,
                            }}
                          >
                            Process
                          </Link>
                          <a
                            className="page__btn page__btn--blue js-popup"
                            data-bg="#E2EAFF"
                            data-child="2"
                            data-btnbg="#1854A2"
                            data-rel="rel2"
                            style={{ padding: "14px 14px", minWidth: 210 }}
                            onClick={() => {
                              navigate("/en/label-beantragen?label=BLUE")
                            }}
                          >
                            Request
                          </a>
                        </div>
                      </td>
                      <td>
                        Renewed answering of the questionnaire*
                        <br />
                        <div className="vergleich__table--button">
                          <Link
                            to="/en/application-procedure-silver"
                            className="page__btn page__btn--blue-border"
                            style={{
                              marginTop: 50,
                              padding: "14px 14px",
                              minWidth: 210,
                            }}
                          >
                            Process
                          </Link>
                          <a
                            className="page__btn page__btn--blue js-popup"
                            data-bg="#FFEBD1"
                            data-child="1"
                            data-btnbg="#C89E67"
                            data-rel="rel1"
                            style={{ padding: "14px 14px", minWidth: 210 }}
                            onClick={() => {
                              navigate("/en/label-beantragen?label=SILVER")
                            }}
                          >
                            Request
                          </a>
                        </div>
                      </td>
                      <td>
                        Renewed Audit* <br />
                        <br />
                        <div className="vergleich__table--button">
                          <Link
                            to="/en/application-procedure-gold"
                            className="page__btn page__btn--blue-border"
                            style={{
                              marginTop: 50,
                              padding: "14px 14px",
                              minWidth: 210,
                            }}
                          >
                            Process
                          </Link>
                          <a
                            className="page__btn page__btn--blue js-popup"
                            data-bg="#FFEBD1"
                            data-child="1"
                            data-btnbg="#C89E67"
                            data-rel="rel1"
                            style={{ padding: "14px 14px", minWidth: 210 }}
                            onClick={() => {
                              navigate("/en/label-beantragen?label=GOLD")
                            }}
                          >
                            Request
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Not sure which label is right for your business? <br></br>
                    Let our experts advise you free of charge.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/free-advice"
                    target="_blank"
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
            </div>

            <div className="vergleich__bezeichnung">
              <sup>*</sup>The requirement criteria are subject to annual review
              and adjusted as necessary.
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Labeloverview
